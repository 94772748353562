.page-investment {
  padding: (255 * 100vw / 1920) 0 (230 * 100vw / 1920);
  @include media($w1024) {
    padding: 120px 0 60px;
  }
}

.page-location,
.page-gallery {
  padding: (255 * 100vw / 1920) 0 (150 * 100vw / 1920);
  @include media($w1024) {
    padding: 120px 0 55px;
  }
}

.page-flats,
.page-flat,
.page-text {
  padding: (255 * 100vw / 1920) 0 (140 * 100vw / 1920);
  @include media($w1024) {
    padding: 120px 0 60px;
  }
}

.page-contact {
  padding: (255 * 100vw / 1920) 0 (85 * 100vw / 1920);
  @include media($w1024) {
    padding: 120px 0 60px;
  }
}

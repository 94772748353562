.ms-parent {
  width: 100% !important;
  display: inline-block;
  position: relative;
}

.ms-choice {
  border: (2 * 100vw / 1920) solid $gray;
  background-color: $white;
  width: 100% !important;
  height: (62 * 100vw / 1920);
  font-family: 'Metropolis' !important;
  font-size: (19 * 100vw / 1920);
  font-weight: 600;
  line-height: (62 * 100vw / 1920);
  text-align: left;
  text-transform: none;
  text-decoration: none;
  overflow: hidden;
  color: $black;
  cursor: pointer;
  white-space: nowrap;
  padding: 0;
  display: block;
  @include media($w1024) {
    border-width: 1px;
    height: 38px;
    font-size: 14px;
    line-height: 38px;
  }
  & > {
    span {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 (35 * 100vw / 1920) 0 (25 * 100vw / 1920);
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      @include media($w1024) {
        padding: 0 20px 0 15px;
      }
      &.placeholder {
        color: $black;
      }
    }
    div {
      @include transition;
      @include translateY;
      background-image: url('../images/icon--select.svg');
      background-size: cover;
      background-position: center center;
      width: (16 * 100vw / 1920);
      height: (9 * 100vw / 1920);
      position: absolute;
      right: (22 * 100vw / 1920);
      @include media($w1024) {
        width: 10px;
        height: 6px;
        right: 12px;
      }
      &.open {
        transform: rotate(180deg);
        top: calc(50% - (5 * 100vw / 1920));
        @include media($w1024) {
          top: calc(50% - 4px);
        }
      }
    }
  }
}

.ms-drop {
  border: (2 * 100vw / 1920) solid $gray;
  background-color: $white;
  width: 100%;
  overflow: hidden;
  margin: (-2 * 100vw / 1920) 0 0;
  padding: 0;
  display: none;
  position: absolute;
  z-index: 1000;
  left: 0;
  @include media($w1024) {
    border-width: 1px;
    margin: -1px 0 0;
  }
  &.bottom {
    top: 100%;
  }
  ul {
    overflow: auto;
    margin: 0;
    padding: (12 * 100vw / 1920) 0 (10 * 100vw / 1920) (25 * 100vw / 1920);
    @include media($w1024) {
      padding: 11px 0 10px 15px;
    }
    & > li {
      background-image: none;
      font-family: 'Metropolis' !important;
      font-size: (16 * 100vw / 1920) !important;
      font-weight: 600 !important;
      line-height: (20 * 100vw / 1920) !important;
      color: $black;
      list-style: none;
      display: list-item;
      position: static;
      @include media($w1024) {
        font-size: 14px !important;
        line-height: 18px !important;
      }
      & + li {
        margin: (8 * 100vw / 1920) 0 0;
        @include media($w1024) {
          margin: 5px 0 0;
        }
      }
      label {
        cursor: pointer;
      }
    }
  }
  input {
    &[type="radio"],
    &[type="checkbox"] {
      vertical-align: middle;
      cursor: pointer;
      margin: 0 (15 * 100vw / 1920) (2 * 100vw / 1920) 0;
      @include media($w1024) {
        margin: 0 8px 2px 0;
      }
    }
  }
}

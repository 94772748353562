.page-home {
  .numbers {
    text-align: center;
    margin: 0 0 (160 * 100vw / 1920);
    padding: 0 (200 * 100vw / 1920);
    @include media($w1024) {
      margin: 0 0 60px;
      padding: 0 30px;
    }
    &__header {
      margin: 0 0 (130 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 45px;
      }
      h2 {
        margin: 0 0 (15 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 10px;
        }
      }
    }
    &__list {
      justify-content: space-between;
      align-items: flex-start;
      display: flex;
      @include media($w1024) {
        flex-wrap: wrap;
        justify-content: space-around;
      }
      @include media($w768) {
        margin: 0 0 -30px;
        justify-content: center;
      }
    }
    &__item {
      @include media($w1024) {
        flex: 0 0 140px;
      }
      @include media($w768) {
        flex: 0 0 calc(100% / 3);
        margin: 0 0 30px;
      }
    }
    &__number {
      width: (158 * 100vw / 1920);
      height: (178 * 100vw / 1920);
      font-size: (60 * 100vw / 1920);
      font-weight: 700;
      line-height: (60 * 100vw / 1920);
      color: $green-1;
      margin: 0 0 (50 * 100vw / 1920);
      padding: (4 * 100vw / 1920) 0 0;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      position: relative;
      @include media($w1024) {
        width: 90px;
        height: 101px;
        font-size: 30px;
        line-height: 36px;
        margin: 0 0 20px;
        padding: 2px 0 0;
      }
      @include media($w768) {
        margin: 0 0 15px;
      }
      @include media($w560) {
        width: 80px;
        height: 90px;
        font-size: 24px;
        line-height: 30px;
      }
    }
    &__number-bg {
      background-image: url(../images/features--addon-02.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: (158 * 100vw / 1920);
      height: (178 * 100vw / 1920);
      position: absolute;
      top: 0;
      left: 0;
      @include media($w1024) {
        width: 90px;
        height: 101px;
      }
      @include media($w560) {
        width: 80px;
        height: 90px;
      }
    }
    &__label {
      font-size: (24 * 100vw / 1920);
      font-weight: 600;
      line-height: (30 * 100vw / 1920);
      @include media($w1024) {
        font-size: 16px;
        line-height: 20px;
      }
      @include media($w560) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.page-home {
  .location {
    margin: 0 0 (220 * 100vw / 1920);
    padding: 0 0 0 (150 * 100vw / 1920);
    display: flex;
    @include media($w1024) {
      margin: 0 0 60px;
      padding: 0 0 0 30px;
    }
    @include media($w768) {
      text-align: center;
      padding: 0;
      flex-wrap: wrap;
    }
    &__copy {
      flex: 0 0 (620 * 100vw / 1920);
      padding: 0 (100 * 100vw / 1920) 0 0;
      @include media($w1024) {
        flex: 0 0 40%;
        padding: 0 30px 0 0;
      }
      @include media($w768) {
        flex: 0 0 100%;
        padding: 0 30px 60px;
      }
      h2 {
        margin: 0 0 (25 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 15px;
        }
      }
    }
    &__features {
      padding: (95 * 100vw / 1920) 0 (90 * 100vw / 1920);
      display: flex;
      @include media($w1024) {
        padding: 28px 0 30px;
      }
    }
    &__item {
      flex: 0 0 (162 * 100vw / 1920);
      @include media($w1024) {
        flex: 0 0 calc(100% / 3);
        padding: 0 15px 0 0;
      }
      @include media($w560) {
        padding: 0 15px 0 0;
      }
      &:nth-child(2) {
        flex: 0 0 (177 * 100vw / 1920);
        @include media($w1024) {
          flex: 0 0 calc(100% / 3);
        }
      }
      &:nth-child(3) {
        flex: 1 1 auto;
        @include media($w1024) {
          flex: 0 0 calc(100% / 3);
        }
      }
      img {
        width: (85.5 * 100vw / 1920);
        margin: 0 0 (45 * 100vw / 1920);
        @include media($w1024) {
          width: 50px;
          margin: 0 0 15px;
        }
        @include media($w768) {
          margin: 0 auto 15px;
        }
      }
      p {
        font-weight: 600;
        line-height: (26 * 100vw / 1920);
        @include media($w1024) {
          line-height: 16px;
        }
      }
    }
    &__map {
      flex: 1 1 auto;
      img {
        width: 100%;
      }
    }
  }
}

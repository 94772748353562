.page-contact {
  .contact {
    &__content {
      padding: 0 (120 * 100vw / 1920) (90 * 100vw / 1920);
      display: flex;
      @include media($w1024) {
        margin: -5px 0 0;
    		padding: 0 30px 55px;
    	}
      @include media($w768) {
        flex-wrap: wrap;
    	}
      h5 {
        font-weight: 600;
        margin: 0 0 (30 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 15px;
      	}
      }
    }
    &__data {
      flex: 0 0 28.5%;
      @include media($w1024) {
        flex: 0 0 34%;
      }
      @include media($w768) {
        flex: 0 0 50%;
    	}
      @include media($w560) {
        flex: 0 0 100%;
    	}
      p {
        margin: 0 0 (20 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 12px;
        }
      }
      a {
        font-size: (24 * 100vw / 1920);
        font-weight: 600;
        line-height: (30 * 100vw / 1920);
        display: inline-block;
        @include media($w1024) {
      		font-size: 14px;
      		line-height: 22px;
      	}
        &:last-of-type {
          margin: 0 0 (20 * 100vw / 1920);
          @include media($w1024) {
            margin: 0 0 12px;
          }
        }
      }
      ul {
        li {
          font-size: (19 * 100vw / 1920);
          line-height: (30 * 100vw / 1920);
          @include media($w1024) {
        		font-size: 14px;
        		line-height: 22px;
        	}
          strong {
            width: (70 * 100vw / 1920);
            font-weight: 600;
            display: inline-block;
            @include media($w1024) {
          		width: 45px;
          	}
          }
          sup {
            top: -0.5em;
          }
        }
      }
    }
    &__open {
      padding: 0 0 0 (35 * 100vw / 1920);
      position: relative;
      @include media($w1024) {
        padding: 0 0 0 22px;
      }
      &:before {
        @include translateY;
        content: '';
        background-image: url(../images/icon--clock.svg);
        background-size: cover;
        width: (20 * 100vw / 1920);
        height: (20 * 100vw / 1920);
        position: absolute;
        top: calc(50% - (1 * 100vw / 1920));
        left: 0;
        @include media($w1024) {
          width: 14px;
          height: 14px;
          top: calc(50% - 1px);
        }
      }
    }
    &__address {
      flex: 0 0 28.5%;
      @include media($w1024) {
        flex: 0 0 28%;
      }
      @include media($w768) {
        flex: 0 0 50%;
    	}
      @include media($w560) {
        flex: 0 0 100%;
        padding: 26px 0 0;
    	}
      p {
        margin: 0 0 (20 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 12px;
        }
        &:last-of-type {
          margin: 0 0 (12 * 100vw / 1920);
          @include media($w1024) {
            margin: 0 0 12px;
          }
        }
      }
    }
    &__form {
      flex: 1 1 auto;
      @include media($w768) {
        flex: 0 0 100%;
        padding: 30px 0 0;
    	}
      h5 {
        margin: 0 0 (35 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 20px;
        }
      }
    }
  }
}

////.page-contact {
////  .contact {
////    &__map {
////      height: (675 * 100vw / 1920);
////      @include media($w1024) {
////        height: 400px;
////      }
////      @include media($w768) {
////        height: 365px;
////      }
////      @include media($w560) {
////        height: 330px;
////      }
////    }
////  }
//}
// iframe
.google_map iframe{
      height: (675 * 100vw / 1920);
       width: 100%;
       display: block;
      @include media($w1024) {
        height: 400px;
      }
      @include media($w768) {
        height: 365px;
      }
      @include media($w560) {
        height: 330px;
  }
}

.range {
	&__slider {
		border: 0 !important;
		background-color: $gray !important;
		width: 100% !important;
		height: (3 * 100vw / 1920) !important;
		margin: 0 0 (10 * 100vw / 1920);
		position: relative;
		@include media($w1024) {
			height: 2px !important;
			margin: 0 0 5px;
		}
		&:after {
			content: '';
			background-color: $gray;
			width: (22 * 100vw / 1920);
			height: 100%;
			position: absolute;
			top: 0;
			left: 100%;
			@include media($w1024) {
				width: 14px;
			}
		}
		.ui-slider-range {
			background-color: $green-1 !important;
		}
		span {
			margin: 0 !important;
			&.ui-slider-handle {
				border: 0 !important;
				border-radius: 0 !important;
				background-color: transparent !important;
				background-image: url(../images/icon--range-slider.svg) !important;
				background-size: cover !important;
				background-position: center center !important;
				width: (22 * 100vw / 1920) !important;
				height: (25 * 100vw / 1920) !important;
				cursor: pointer !important;
				top: (-10 * 100vw / 1920) !important;
				@include media($w1024) {
					width: 14px !important;
					height: 15px !important;
					top: -6px !important;
				}
			}
		}
	}
	&__amount {
		width: calc(100% + (12 * 100vw / 1920));
		font-size: (19 * 100vw / 1920);
		line-height: (30 * 100vw / 1920);
		color: $black;
		margin: 0 (-17 * 100vw / 1920) (15 * 100vw / 1920) (5 * 100vw / 1920);
		justify-content: space-between;
		display: flex;
		@include media($w1024) {
			width: calc(100% + 8px);
			font-size: 14px;
			line-height: 20px;
			margin: 0 0 7px 3px;
		}
		sup {
			top: -0.45em;
		}
	}
}

.page-location {
  .location {
    &__content {
      display: flex;
      @include media($w768) {
        flex-wrap: wrap;
      }
    }
  }
}

.page-home {
  .features {
    margin: 0 0 (170 * 100vw / 1920);
    @include media($w1024) {
      margin: 0 0 60px;
    }
    &:before {
      content: '';
      background-image: url(../images/features--addon-02.svg);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      width: (143 * 100vw / 1920);
      height: (161 * 100vw / 1920);
      position: absolute;
      top: (100 * 100vw / 1920);
      left: calc(100% - (60 * 100vw / 1920));
      @include media($w1024) {
        display: none;
      }
    }
  }
}

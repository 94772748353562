input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
	@include transition;
	border: 1px solid $gray;
	border-radius: 0;
	background-color: $gray;
	width: 100%;
	height: (58 * 100vw / 1920);
	font-family: 'Metropolis' !important;
	font-size: (19 * 100vw / 1920);
	line-height: (30 * 100vw / 1920);
	color: $black;
	outline: none;
	margin: 0 0 (11 * 100vw / 1920);
	padding: 0 (30 * 100vw / 1920);
	box-shadow: none !important;
	-webkit-appearance: none;
	@include media($w1024) {
		height: 36px;
		font-size: 13px;
		line-height: 17px;
		margin: 0 0 8px;
		padding: 0 15px;
	}
	&:focus {
		border-color: $green-1;
		background-color: $gray;
	}
}

input[type="submit"] {
	@include transition;
	cursor: pointer;
	padding-bottom: (2 * 100vw / 1920);
	-webkit-appearance: none;
}

textarea {
	height: (100 * 100vw / 1920);
	resize: none;
	padding: (15 * 100vw / 1920) (30 * 100vw / 1920);
	@include media($w1024) {
		height: 100px;
		padding: 10px 15px;
	}
}

::-webkit-input-placeholder {
	color: $black !important;
	opacity: 1;
}

:-moz-placeholder {
	color: $black !important;
	opacity: 1;
}

::-moz-placeholder {
	color: $black !important;
	opacity: 1;
}

:-ms-input-placeholder {
	color: $black !important;
	opacity: 1;
}

button {
	@include transition;
	border: 0;
	border-radius: 0;
	background-color: transparent;
	font-family: 'Metropolis' !important;
	font-weight: 400;
	cursor: pointer;
	margin: 0;
	padding: 0;
	-webkit-appearance: none;
}

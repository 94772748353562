.background {
  width: (1954 * 100vw / 1920);
  pointer-events: none;
  position: absolute;
  bottom: (-17 * 100vw / 1920);
  left: (-17 * 100vw / 1920);
  img {
    width: 100%;
  }
}

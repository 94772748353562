.popup {
	&__content {
		background-color: $white;
		width: (830 * 100vw / 1920);
		margin: (30 * 100vw / 1920) 0;
		padding: (45 * 100vw / 1920) (65 * 100vw / 1920) (55 * 100vw / 1920);
		@include media($w1024) {
			width: 600px;
			margin: 20px 0;
			padding: 25px 30px 30px;
		}
		@include media($w768) {
			width: 500px;
		}
		@include media($w560) {
			width: calc(100% - 60px);
			margin: 20px 30px;
		}
	}
	&__header {
		margin: 0 0 (40 * 100vw / 1920);
		@include media($w1024) {
			margin: 0 0 25px;
		}
	}
	&__close {
		@include transition;
		background-color: $green-1;
		width: (65 * 100vw / 1920);
		height: (65 * 100vw / 1920);
		cursor: pointer;
		position: absolute;
		top: (30 * 100vw / 1920);
		right: 0;
		@include media($w1024) {
			width: 40px;
			height: 40px;
			top: 20px;
		}
		@include media($w560) {
			right: 30px;
		}
		img {
			@include translate;
			width: (31 * 100vw / 1920);
			@include media($w1024) {
				width: 14px;
			}
		}
		&:hover {
			background-color: darken($green-1,5%);
		}
	}
}

.btn {
	border: 0;
	border-radius: (29 * 100vw / 1920);
	background-color: $green-1;
	width: auto;
	height: (58 * 100vw / 1920);
	font-size: (18 * 100vw / 1920);
	font-weight: 400;
	line-height: (22 * 100vw / 1920);
	color: $white;
	padding: 0 (70 * 100vw / 1920);
	align-items: center;
	display: inline-flex;
	position: relative;
	@include media($w1024) {
		border-radius: 18px;
		height: 36px;
		font-size: 14px;
		line-height: 18px;
		padding: 0 30px;
	}
	@include media($w560) {
		padding: 0 20px;
	}
	&:hover {
		background-color: darken($green-1,10%);
		color: $white;
	}
	&--large {
		border-radius: (32.5 * 100vw / 1920);
		height: (65 * 100vw / 1920);
		font-size: (24 * 100vw / 1920);
		font-weight: 600;
		line-height: (29 * 100vw / 1920);
		padding: 0 (45 * 100vw / 1920);
		@include media($w1024) {
			border-radius: 18px;
			height: 36px;
			font-size: 14px;
			line-height: 18px;
			padding: 0 30px;
		}
		@include media($w560) {
			padding: 0 20px;
		}
	}
	&--flats-table {
		border: (2 * 100vw / 1920) solid $green-1;
		background-color: transparent;
		height: (48 * 100vw / 1920);
		font-size: (17 * 100vw / 1920);
		font-weight: 500;
		line-height: (21 * 100vw / 1920);
		padding: 0 (30 * 100vw / 1920) 0 (65 * 100vw / 1920);
		@include media($w1024) {
			border-width: 2px;
			height: 36px;
			font-size: 14px;
			line-height: 18px;
			padding: 0 20px 0 40px;
		}
		&:before {
			@include translateY;
			content: '';
			background-image: url(../images/icon--home.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			width: (18 * 100vw / 1920);
			height: (18 * 100vw / 1920);
			position: absolute;
			top: calc(50% - (1 * 100vw / 1920));
			left: (26 * 100vw / 1920);
			@include media($w1024) {
				width: 13px;
				height: 13px;
				top: calc(50% - 1px);
				left: 16px;
			}
		}
		&:hover {
			background-color: $green-1;
		}
	}
	&--steps {
		border: (2 * 100vw / 1920) solid $white;
		background-color: transparent;
		height: (48 * 100vw / 1920);
		font-size: (17 * 100vw / 1920);
		font-weight: 500;
		line-height: (21 * 100vw / 1920);
		padding: 0 (55 * 100vw / 1920) 0 (30 * 100vw / 1920);
		@include media($w1024) {
			border-width: 2px;
			height: 36px;
			font-size: 14px;
			line-height: 18px;
			padding: 0 35px 0 20px;
		}
		&:before,
		&:after {
			@include transition;
			@include translateY;
			content: '';
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			width: (10 * 100vw / 1920);
			height: (16 * 100vw / 1920);
			position: absolute;
			right: (25 * 100vw / 1920);
			@include media($w1024) {
				width: 7px;
				height: 11px;
				top: calc(50% - 1px);
				right: 12px;
			}
		}
		&:before {
			background-image: url(../images/icon--next.svg);
		}
		&:after {
			background-image: url(../images/icon--next--black.svg);
			opacity: 0;
		}
		&:hover {
			background-color: $white;
			color: $black;
			&:before {
				opacity: 0;
			}
			&:after {
				opacity: 1;
			}
		}
	}
	&--rooms {
		width: 100%;
		padding: 0 0 0 (78 * 100vw / 1920);
		@include media($w1024) {
			padding: 0 0 0 45px;
		}
		&:before,
		&:after {
			@include translateY;
			content: '';
			position: absolute;
		}
		&:before {
			background-size: cover;
			width: (23 * 100vw / 1920);
			height: (23 * 100vw / 1920);
			left: (26 * 100vw / 1920);
			@include media($w1024) {
				width: 16px;
				height: 16px;
				left: 18px;
			}
		}
		&:after {
			background-image: url(../images/icon--next.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			width: (13 * 100vw / 1920);
			height: (19 * 100vw / 1920);
			right: (25 * 100vw / 1920);
			@include media($w1024) {
				width: 7px;
				height: 11px;
				top: calc(50% - 1px);
				right: 12px;
			}
		}
		// &--01 {
		// 	background-color: $green-1;
		// 	&:before {
		// 		background-image: url(../images/icon--1-room.svg);
		// 	}
		// 	&:hover {
		// 		background-color: darken($green-1,5%);
		// 	}
		// }
		&--02 {
			background-color: $green-1;
			&:before {
				background-image: url(../images/icon--2-rooms.svg);
			}
			&:hover {
				background-color: darken($green-1,5%);
			}
		}
		&--03 {
			background-color: $green-2;
			&:before {
				background-image: url(../images/icon--3-rooms.svg);
			}
			&:hover {
				background-color: darken($green-2,5%);
			}
		}
	}
	&--details {
		background-color: $gray;
		border-radius: (22 * 100vw / 1920);
		height: (44 * 100vw / 1920);
		color: $black;
		padding: 0 (32 * 100vw / 1920);
		@include media($w1024) {
			border-radius: 15px;
			height: 30px;
			padding: 0 15px;
		}
		&:hover {
			background-color: $green-1;
			color: $white;
		}
	}
	&--shadow {
		box-shadow: 0 (20 * 100vw / 1920) (20 * 100vw / 1920) 0 rgba($green-1,0.5);
		@include media($w1024) {
			box-shadow: 0 12px 12px 0 rgba($green-1,0.5);
		}
		&:hover {
			box-shadow: 0 (10 * 100vw / 1920) (20 * 100vw / 1920) 0 rgba($green-1,0.5);
			@include media($w1024) {
				box-shadow: 0 12px 12px 0 rgba($green-1,0.5);
			}
		}
	}
	&--pdf {
		background-color: $gray;
		color: $black;
		padding: 0 (40 * 100vw / 1920) 0 (76 * 100vw / 1920);
		@include media($w1024) {
			padding: 0 25px 0 45px;
		}
		&:after {
			@include translateY;
			content: '';
			background-image: url(../images/icon--pdf.svg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			width: (26 * 100vw / 1920);
			height: (26 * 100vw / 1920);
			position: absolute;
			left: (30 * 100vw / 1920);
			@include media($w1024) {
				width: 16px;
				height: 16px;
				left: 18px;
			}
		}
		&:hover {
			background-color: darken($gray,5%);
			color: $black;
		}
	}
}

.header {
  @include transition;
  width: 100%;
  padding: 0 (30 * 100vw / 1920);
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  @include media($w1024) {
    padding: 0;
  }
  &__first-line {
    border-bottom: (1 * 100vw / 1920) solid rgba($white,0.15);
    height: (109 * 100vw / 1920);
    padding: (6 * 100vw / 1920) (30 * 100vw / 1920) 0;
    justify-content: space-between;
    align-items: center;
    display: flex;
    @include media($w1024) {
      border-bottom-width: 1px;
      height: 71px;
      padding: 0 30px;
    }
  }
  &__logo {
    img {
      width: (337 * 100vw / 1920);
      display: inline;
      @include media($w1024) {
        width: auto;
        height: 45px;
      }
    }
    > img {
      width: (158 * 100vw / 1920);
      margin-left: (48 * 100vw / 1920);
      position: relative;
      left: 0;
      top: (5 * 100vw / 1920);
      @include media($w1024) {
        width: auto;
        height: 45px;
        top: 3px;
      }
      @include media($w560) {
        display: none;
      }
    }
  }
  &__contact {
    font-size: (17 * 100vw / 1920);
    line-height: (22 * 100vw / 1920);
    color: $white;
    margin: 0 (45 * 100vw / 1920) 0 auto;
    @include media($w1024) {
      font-size: 13px;
      line-height: 17px;
      margin: 0 30px 0 auto;
      display: none;
    }
    span {
      @include media($w1024) {
        display: none;
      }
    }
    a {
      color: $white;
      margin: 0 0 0 (30 * 100vw / 1920);
      display: inline-block;
      @include media($w1024) {
        margin: 2px 0 0;
      }
      & + a {
        @include media($w1024) {
          margin: 2px 0 0 20px;
        }
      }
      &:hover {
        color: $white;
        opacity: 0.75;
      }
    }
  }
  &__buttons {
    @include media($w1024) {
      display: none;
    }
    a {
      & + a {
        &.btn {
          margin: 0 0 0 (12 * 100vw / 1920);
          @include media($w1024) {
            margin: 0 0 0 10px;
          }
        }
      }
    }
  }
  &__second-line {
    padding: 0 (60 * 100vw / 1920);
    justify-content: flex-end;
    display: flex;
    position: relative;
    @include media($w1024) {
      width: 100%;
      pointer-events: none;
      padding: 0 30px;
      position: absolute;
      top: 0;
      left: 0;
    }
    @include media($w768) {
      height: 70px;
    }
  }
  &__menu {
    @include media($w768) {
      @include transition;
      background-color: $black;
      width: 100vw;
      height: 100vh;
      opacity: 0;
      pointer-events: none;
      justify-content: center;
      align-items: center;
      display: flex;
      position: absolute;
      top: -30px;
      left: 0;
    }
    ul {
      display: flex;
      @include media($w768) {
        flex-wrap: wrap;
      }
      li {
        height: (76 * 100vw / 1920);
        align-items: center;
        display: flex;
        @include media($w1024) {
          height: 71px;
          pointer-events: auto;
          padding: 6px 0 0;
        }
        @include media($w768) {
          flex: 0 0 100%;
          height: auto;
          text-align: center;
          pointer-events: none;
          display: block;
        }
        &:before {
          content: '';
          background-color: $green-1;
          width: 100%;
          height: (4 * 100vw / 1920);
          display: none;
          position: absolute;
          top: (-2 * 100vw / 1920);
          left: 0;
          @include media($w1024) {
            height: 3px;
            top: auto;
            bottom: -1px;
          }
          @include media($w768) {
            display: none;
          }
        }
        & + li {
          margin: 0 0 0 (45 * 100vw / 1920);
          @include media($w1024) {
            margin: 0 0 0 20px;
          }
          @include media($w768) {
            margin: 20px 0 0;
          }
        }
        a {
          font-size: (17 * 100vw / 1920);
          font-weight: 500;
          line-height: (22 * 100vw / 1920);
          color: $white;
          padding: 0 (5 * 100vw / 1920);
          display: inline-block;
          position: relative;
          @include media($w1024) {
            font-size: 13px;
            line-height: 17px;
            padding: 0 3px;
          }
          @include media($w768) {
            font-size: 16px;
            line-height: 24px;
            padding: 0;
          }
          @include media($w768) {
            font-size: 14px;
            line-height: 22px;
          }
          &:hover {
            color: $white;
            opacity: 0.75;
          }
        }
        &.current-menu-item {
          @include media($w768) {
            a {
              color: $green-1;
            }
          }
          &:before {
            display: block;
            @include media($w768) {
              display: none;
            }
          }
        }
      }
    }
    &.visible {
      opacity: 1;
      pointer-events: auto;
      top: 0;
      ul {
        li {
          pointer-events: auto;
        }
      }
    }
  }
  &__trigger {
    width: 24px;
    height: 18px;
    cursor: pointer;
    pointer-events: auto;
    overflow: hidden;
    display: none;
    position: absolute;
    z-index: 75;
    top: 29px;
    right: 30px;
    @include media($w768) {
      display: block;
    }
    span {
      @include transition;
      @include translateY;
      background-color: $white;
      width: 100%;
      height: 2px;
      position: absolute;
      left: 0;
      &:before,
      &:after {
        @include transition;
        content: '';
        background-color: $white;
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
      }
      &:before {
        top: -7px;
      }
      &:after {
        bottom: -7px;
      }
    }
    &.visible {
      position: fixed;
      span {
        background-color: transparent;
        &:before {
          transform: rotate(45deg);
          top: 0;
        }
        &:after {
          transform: rotate(-45deg);
          bottom: 0;
        }
      }
    }
  }
  &--subpage {
    background-color: $green-3;
  }
  &.scrolling {
    background-color: $green-3;
    box-shadow: 0 (5 * 100vw / 1920) (30 * 100vw / 1920) 0 rgba($black,0.15);
    .header {
      &__first-line {
        @include media($w1024) {
          border-bottom-color: transparent;
        }
      }
    }
  }
}

.page-investment {
  .benefits {
    padding: (170 * 100vw / 1920) (120 * 100vw / 1920) (140 * 100vw / 1920);
    @include media($w1024) {
      padding: 60px 30px;
    }
    h2 {
      text-align: center;
      margin: 0 0 (115 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 50px;
      }
    }
    &__list {
      margin: 0 0 (-60 * 100vw / 1920);
      flex-wrap: wrap;
      display: flex;
      @include media($w1024) {
        justify-content: space-between;
        margin: 0 0 -30px;
      }
    }
    &__item {
      flex: 0 0 calc(100% / 3);
      margin: 0 0 (60 * 100vw / 1920);
      padding: 0 (30 * 100vw / 1920) 0 0;
      justify-content: space-between;
      align-items: center;
      display: flex;
      @include media($w1024) {
        flex: 0 0 calc(100% / 3 - 15px);
        margin: 0 0 30px;
        padding: 0;
      }
      @include media($w768) {
        flex: 0 0 calc(100% / 2 - 10px);
        text-align: center;
        flex-wrap: wrap;
        align-items: flex-start;
        align-content: flex-start;
      }
      img {
        height: (75 * 100vw / 1920);
        @include media($w1024) {
          height: 50px;
        }
        @include media($w768) {
          margin: 0 auto 15px;
        }
        @include media($w560) {
          height: 45px;
        }
      }
      p {
        flex: 0 0 calc(100% - (100 * 100vw / 1920));
        font-size: (24 * 100vw / 1920);
        font-weight: 600;
        line-height: (30 * 100vw / 1920);
        @include media($w1024) {
          flex: 0 0 calc(100% - 70px);
          font-size: 16px;
          line-height: 22px;
        }
        @include media($w768) {
          flex: 0 0 100%;
          font-size: 15px;
          line-height: 21px;
        }
        @include media($w560) {
          font-size: 14px;
          line-height: 20px;
        }
        br {
          @include media($w1024) {
            display: none;
          }
        }
      }
    }
  }
}

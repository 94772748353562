.page-flat {
  .flat {
    &__left {
      flex: 0 0 (480 * 100vw / 1920);
      @include media($w1024) {
        flex: 0 0 38%;
      }
      @include media($w768) {
        flex: 0 0 100%;
        margin: 0 0 30px;
      }
    }
    &__characteristics {
      border: (1 * 100vw / 1920) solid $green-1;
      margin: 0 0 (15 * 100vw / 1920);
      @include media($w1024) {
        border-width: 1px;
        margin: 0 0 10px;
      }
      @include media($w768) {
        margin: -8px 0 10px;
      }
      ul {
        padding: (15 * 100vw / 1920) 0;
        display: flex;
        @include media($w1024) {
          padding: 10px 0;
        }
        li {
          flex: 0 0 calc(100% / 3);
          font-size: (36 * 100vw / 1920);
          font-weight: 700;
          line-height: (42 * 100vw / 1920);
          padding: 0 (25 * 100vw / 1920);
          @include media($w1024) {
            font-size: 24px;
            line-height: 30px;
            padding: 0 15px;
          }
          & + li {
            border-left: (1 * 100vw / 1920) solid $green-1;
            @include media($w1024) {
              border-left-width: 1px;
            }
          }
          small {
            font-size: (19 * 100vw / 1920);
            font-weight: 500;
            line-height: (22 * 100vw / 1920);
            margin: (5 * 100vw / 1920) 0 0;
            display: block;
            @include media($w1024) {
              font-size: 13px;
              line-height: 17px;
              margin: 2px 0 0;
            }
          }
        }
      }
    }
    &__meters {
      border: (1 * 100vw / 1920) solid $green-1;
      margin: 0 0 (50 * 100vw / 1920);
      padding: (30 * 100vw / 1920) (40 * 100vw / 1920) (28 * 100vw / 1920);
      @include media($w1024) {
        border-width: 1px;
        margin: 0 0 30px;
        padding: 15px;
      }
      ul {
        li {
          font-size: (19 * 100vw / 1920);
          line-height: (26 * 100vw / 1920);
          justify-content: space-between;
          display: flex;
          @include media($w1024) {
            font-size: 13px;
            line-height: 19px;
          }
          & + li {
            margin: (10 * 100vw / 1920) 0 0;
            @include media($w1024) {
              margin: 6px 0 0;
            }
          }
          span {
            padding: 0 (15 * 100vw / 1920) 0 0;
            display: inline-block;
            @include media($w1024) {
              padding: 0 10px 0 0;
            }
          }
          strong {
            @include media($w1024) {
              flex: 0 0 65px;
              text-align: right;
            }
          }
        }
      }
    }
    &__meters-top {
      border-bottom: (1 * 100vw / 1920) solid $green-1;
      margin: 0 0 (25 * 100vw / 1920);
      padding: 0 0 (23 * 100vw / 1920);
      @include media($w1024) {
        border-bottom-width: 1px;
        margin: 0 0 15px;
        padding: 0 0 14px;
      }
    }
    &__buttons {
      @include media($w768) {
        text-align: center;
      }
      .btn {
        margin: 0 0 (45 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 20px;
        }
        &:last-of-type {
          margin: 0;
        }
      }
    }
  }
}

a {
	@include transition;
	text-decoration: none;
	color: $black;
	&:hover {
		color: $green-1;
	}
}

h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	margin: 0;
}

h1 {
	font-size: (60 * 100vw / 1920);
	line-height: (62 * 100vw / 1920);
	@include media($w1024) {
		font-size: 36px;
		line-height: 40px;
	}
	@include media($w768) {
		font-size: 33px;
		line-height: 37px;
	}
	@include media($w560) {
		font-size: 30px;
		line-height: 34px;
	}
}

h2 {
	font-size: (48 * 100vw / 1920);
	line-height: (55 * 100vw / 1920);
	color: $green-3;
	@include media($w1024) {
		font-size: 30px;
		line-height: 36px;
	}
	@include media($w768) {
		font-size: 27px;
		line-height: 33px;
	}
	@include media($w560) {
		font-size: 24px;
		line-height: 30px;
	}
}

h3 {
	font-size: (42 * 100vw / 1920);
	line-height: (59 * 100vw / 1920);
	@include media($w1024) {
		font-size: 26px;
		line-height: 32px;
	}
	@include media($w768) {
		font-size: 23px;
		line-height: 29px;
	}
	@include media($w560) {
		font-size: 20px;
		line-height: 26px;
	}
}

h4 {
	font-size: (36 * 100vw / 1920);
	line-height: (43 * 100vw / 1920);
	@include media($w1024) {
		font-size: 24px;
		line-height: 30px;
	}
	@include media($w768) {
		font-size: 21px;
		line-height: 27px;
	}
	@include media($w560) {
		font-size: 18px;
		line-height: 24px;
	}
}

h5 {
	font-size: (24 * 100vw / 1920);
	line-height: (30 * 100vw / 1920);
	@include media($w1024) {
		font-size: 16px;
		line-height: 24px;
	}
}

h6 {
	font-size: (19 * 100vw / 1920);
	line-height: (30 * 100vw / 1920);
	@include media($w1024) {
		font-size: 14px;
		line-height: 22px;
	}
}

p {
	font-size: (19 * 100vw / 1920);
	line-height: (30 * 100vw / 1920);
	margin: 0;
	@include media($w1024) {
		font-size: 14px;
		line-height: 22px;
	}
}

strong, b {
	font-weight: 700;
}

.c {
	h2, h3, h4, h5, h6 {
		color: $green-3;
		margin: 0 0 (32.5 * 100vw / 1920);
		@include media($w1024) {
	    margin: 0 0 20px;
	  }
	}
	p {
		margin: 0 0 (30 * 100vw / 1920);
		@include media($w1024) {
	    margin: 0 0 17px;
	  }
	}
	ul, ol {
		margin: 0 0 (30 * 100vw / 1920);
		@include media($w1024) {
	    margin: 0 0 22px;
	  }
		li {
			font-size: (19 * 100vw / 1920);
			line-height: (30 * 100vw / 1920);
			@include media($w1024) {
				font-size: 14px;
				line-height: 22px;
			}
			& + li {
				margin: (15 * 100vw / 1920) 0 0;
				@include media($w1024) {
					margin: 10px 0 0;
				}
			}
		}
	}
	ul {
		li {
			padding: 0 0 0 (30 * 100vw / 1920);
			@include media($w1024) {
				padding: 0 0 0 25px;
			}
			&:before {
				content: '';
				background-image: url(../images/icon--list.svg);
				background-size: cover;
				width: (17.22 * 100vw / 1920);
				height: (19.43 * 100vw / 1920);
				position: absolute;
				top: (5 * 100vw / 1920);
				left: 0;
				@include media($w1024) {
					width: 13px;
					height: 15px;
					top: 3px;
		    }
			}
		}
	}
	ol {
		counter-reset: ol-list;
		li {
			padding: 0 0 0 (66 * 100vw / 1920);
			@include media($w1024) {
				padding: 0 0 0 40px;
			}
			&:before {
				content: counter(ol-list);
				background-image: url(../images/icon--list.svg);
				background-size: cover;
				width: (39.7 * 100vw / 1920);
				height: (44.79 * 100vw / 1920);
				font-size: (19 * 100vw / 1920);
				font-weight: 600;
				line-height: (30 * 100vw / 1920);
				color: $white;
				counter-increment: ol-list;
				justify-content: center;
				align-items: center;
				display: flex;
				position: absolute;
				top: (5 * 100vw / 1920);
				left: 0;
				@include media($w1024) {
					width: 30px;
					height: 34px;
					font-size: 14px;
					line-height: 20px;
					top: 4px;
				}
			}
		}
	}
	& > * {
		&:last-child {
			margin-bottom: 0;
		}
	}
}

.page-location {
	.location {
		&__map {
			flex: 1 1 auto;
			margin: (-70 * 100vw / 1920) 0 0;
			@include media($w1024) {
				margin: -50px 0 0;
			}
			@include media($w768) {
				margin: 60px 0 0;
			}
			
			img {
				width: 100%;
				display: block;
			}
			
			&__wrapper {
				position: relative;
				
				.pin {
					position: absolute;
					top: 87.6%;
					left: 51.2%;
					width: 70px;
					height: 70px;
					transform: translateY(-50%) translateX(-50%);
					
					@media (max-width: 767px) {
						width: 50px;
						height: 50px;
					}
					
					&:after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: transparent;
						display: block;
						border-radius: 50%;
						border: 8px solid #96b955;
						opacity: 0;
						animation-name: pulse;
						animation-duration: 1s;
						animation-iteration-count: infinite;
						transform-origin: 50% 50%;
						@media (max-width: 767px) {
							border-width: 4px;
						}
					}
				}
			}
		}
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
		opacity: 0;
	}
	
	50% {
		opacity: 0.3;
	}
	
	100% {
		transform: scale(1.8);
		opacity: 0;
	}
}

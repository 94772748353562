@keyframes imageZoom {
  0% {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  50% {
    width: 108%;
    height: 108%;
    top: -4%;
    left: -4%;
  }
  100% {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}

.page-home {
  .slider {
    height: (965 * 100vw / 1920);
    color: $white;
    margin: 0 0 (120 * 100vw / 1920);
    @include media($w1024) {
      height: 500px;
      margin: 0 0 60px;
    }
    @include media($w768) {
      height: 475px;
    }
    @include media($w560) {
      height: 450px;
    }
    &__slide {
      padding: (205 * 100vw / 1920) (120 * 100vw / 1920) (140 * 100vw / 1920);
      flex-wrap: wrap;
      align-content: space-between;
      display: flex !important;
      @include media($w1024) {
        padding: 100px 30px 40px;
      }
      @include media($w768) {
        padding: 100px 30px 35px;
      }
      @include media($w560) {
        padding: 100px 30px 30px;
      }
      &.swiper-slide-active {
        .slider {
          &__image {
            animation-name: imageZoom;
            animation-duration: 4.5s;
            animation-iteration-count: 1;
          }
          &__addon {
            opacity: 1;
            left: 0;
          }
          &__copy {
            .btn {
              opacity: 1;
              bottom: 0;
            }
          }
          &__copy-wrapper {
            opacity: 1;
            top: 0;
          }
        }
      }
    }
    &__image {
      @include transition;
      background-size: cover;
      background-position: center center;
      width: 100%;
      height: 100%;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      &:before {
        content: '';
        background-color: rgba($brown,0.65);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    &__addon {
      @include transitionSlow;
      flex: 0 0 100%;
      opacity: 0;
      position: relative;
      z-index: 25;
      left: (-120 * 100vw / 1920);
      @include media($w1024) {
        left: -50px;
      }
      @include media($w560) {
        left: -40px;
      }
      span {
        background-image: url(../images/home--slide--number-bg.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        width: (236 * 100vw / 1920);
        height: (266 * 100vw / 1920);
        font-size: (71 * 100vw / 1920);
        font-weight: 700;
        line-height: (73 * 100vw / 1920);
        text-align: center;
        flex-wrap: wrap;
        align-content: center;
        display: inline-flex;
        position: relative;
        @include media($w1024) {
          width: 115px;
          height: 135px;
          font-size: 40px;
          line-height: 48px;
          padding: 0 0 5px;
        }
        @include media($w560) {
          width: 105px;
          height: 120px;
          font-size: 30px;
          line-height: 36px;
          padding: 0 0 3px;
        }
        &:after {
          content: '';
          background-image: url(../images/home--slide--number-addon.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
          width: (96 * 100vw / 1920);
          height: (210 * 100vw / 1920);
          pointer-events: none;
          position: absolute;
          z-index: -25;
          top: (85 * 100vw / 1920);
          left: calc(100% - (35 * 100vw / 1920));
          @include media($w1024) {
            width: 40px;
            height: 88px;
            top: 40%;
            left: calc(100% - 15px);
          }
          @include media($w560) {
            top: 35%;
          }
        }
        strong {
          flex: 0 0 100%;
          margin: 0 0 (10 * 100vw / 1920);
          @include media($w1024) {
            margin: 0;
          }
        }
        small {
          flex: 0 0 100%;
          font-size: (22 * 100vw / 1920);
          line-height: (28 * 100vw / 1920);
          @include media($w1024) {
            font-size: 14px;
            line-height: 16px;
          }
          @include media($w560) {
            font-size: 13px;
            line-height: 15px;
          }
        }
      }
    }
    &__copy {
      flex: 0 0 100%;
      position: relative;
      z-index: 25;
      h1 {
        letter-spacing: 0.02em;
        margin: 0 0 (10 * 100vw / 1920);
        @include media($w1024) {
      		margin: 0 0 5px;
      	}
        @include media($w768) {
      		margin: 0 0 9px;
      	}
      }
      p {
        font-size: (22 * 100vw / 1920);
        line-height: (30 * 100vw / 1920);
        margin: 0 0 (50 * 100vw / 1920);
        @include media($w1024) {
      		font-size: 16px;
      		line-height: 24px;
          margin: 0 0 25px;
      	}
        @include media($w768) {
      		font-size: 15px;
      		line-height: 23px;
          margin: 0 0 20px;
      	}
        @include media($w560) {
          max-width: 80%;
      		font-size: 14px;
      		line-height: 22px;
      	}
      }
      .btn {
        @include transitionSlow;
        opacity: 0;
        bottom: (-40 * 100vw / 1920);
        @include media($w1024) {
          bottom: -30px;
        }
        @include media($w560) {
          bottom: -20px;
        }
      }
    }
    &__copy-wrapper {
      @include transitionSlow;
      opacity: 0;
      position: relative;
      top: (-70 * 100vw / 1920);
      @include media($w1024) {
        top: -30px;
      }
      @include media($w560) {
        top: -20px;
      }
    }
    &__nav {
      width: (150 * 100vw / 1920);
      align-items: center;
      justify-content: space-between;
      display: flex;
      position: absolute;
      z-index: 200;
      bottom: (50 * 100vw / 1920);
      right: (65 * 100vw / 1920);
      @include media($w1024) {
        width: 70px;
        bottom: 44px;
        right: 30px;
      }
      @include media($w768) {
        bottom: 39px;
      }
      @include media($w560) {
        bottom: 34px;
      }
    }
    &__prev,
    &__next {
      background-size: contain;
      background-repeat: no-repeat;
      width: (35 * 100vw / 1920);
      height: (52 * 100vw / 1920);
      cursor: pointer;
      @include media($w1024) {
        width: 20px;
        height: 30px;
      }
    }
    &__prev {
      background-image: url(../images/icon--prev.svg);
      background-position: center left;
    }
    &__next {
      background-image: url(../images/icon--next.svg);
      background-position: center right;
    }
  }
}

.footer {
  padding: 0 (120 * 100vw / 1920) (60 * 100vw / 1920);
  justify-content: flex-end;
  display: flex;
  position: relative;
  z-index: 25;
  @include media($w1024) {
    padding: 0 30px 40px;
  }
  @include media($w768) {
    text-align: center;
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
  @include media($w560) {
    padding: 0 30px 35px;
  }
  &__author {
    @include media($w768) {
      flex: 0 0 100%;
    }
    p {
      color: rgba($black,0.5);
      margin: 0 (30 * 100vw / 1920) 0 0;
      a {
        color: rgba($black,0.5);
        &:hover {
          color: $green-1;
        }
      }
    }
  }
  &__copyright {
    @include media($w768) {
      flex: 0 0 100%;
      margin: 0 0 5px;
    }
  }
}

.page-flats {
  .flats {
    &__table {
      padding: 0 (120 * 100vw / 1920);
      @include media($w1024) {
        padding: 0 30px;
      }
    }
    &__empty {
      border-top: (1 * 100vw / 1920) solid $gray;
      text-align: center;
      padding: (50 * 100vw / 1920) 0 0;
      display: none;
      @include media($w1024) {
        border-top-width: 1px;
        padding: 30px 0 0;
      }
      @include media($w768) {
        padding: 25px 0 0;
      }
      @include media($w560) {
        padding: 20px 0 0;
      }
    }
    &__button {
      margin: (60 * 100vw / 1920) 0 0;
      padding: 0 (120 * 100vw / 1920);
      @include media($w1024) {
        margin: 50px 0 0;
        padding: 0 30px;
      }
      @include media($w560) {
        text-align: center;
      }
    }
  }
}

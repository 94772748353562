.socials {
  display: flex;
  &__item {
    flex: 0 0 (40 * 100vw / 1920);
    display: flex;
    @include media($w1024) {
      flex: 0 0 30px;
    }
    & + .socials {
      &__item {
        margin: 0 0 0 (14 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 0 10px;
        }
      }
    }
    a {
      border-radius: 50%;
      background-color: $gray;
      flex: 0 0 (40 * 100vw / 1920);
      height: (40 * 100vw / 1920);
      justify-content: center;
      align-items: center;
      display: inline-flex;
      @include media($w1024) {
        flex: 0 0 30px;
        height: 30px;
      }
      svg {
        path {
          @include transition;
          fill: $black;
        }
      }
      &:hover {
        background-color: $green-1;
        svg {
          path {
            fill: $white;
          }
        }
      }
    }
    &--youtube {
      a {
        svg {
          width: (22 * 100vw / 1920);
          @include media($w1024) {
            width: 16px;
          }
        }
      }
    }
    &--facebook {
      a {
        svg {
          width: (7.5 * 100vw / 1920);
          @include media($w1024) {
            width: 6px;
          }
        }
      }
    }
    &--instagram {
      a {
        svg {
          width: (18 * 100vw / 1920);
          @include media($w1024) {
            width: 13px;
          }
        }
      }
    }
  }
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Light.woff2') format('woff2'),
       url('../fonts/Metropolis-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Regular.woff2') format('woff2'),
       url('../fonts/Metropolis-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Medium.woff2') format('woff2'),
       url('../fonts/Metropolis-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-SemiBold.woff2') format('woff2'),
       url('../fonts/Metropolis-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Metropolis';
  src: url('../fonts/Metropolis-Bold.woff2') format('woff2'),
       url('../fonts/Metropolis-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

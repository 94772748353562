* {
	@include box-sizing;
	&:before,
	&:after {
		@include box-sizing;
	}
}

::-moz-selection {
	background: $black;
	color: $white;
}

::selection {
	background: $black;
	color: $white;
}

body {
	background-color: $white;
	font-family: 'Metropolis' !important;
	font-size: 16px;
	font-weight: normal;
	line-height: 16px;
	color: $black;
	margin: 0;
	padding: 0;
}

img {
	margin: 0;
	display: block;
	user-select: none;
}

/* --- [ global styles ] --- */

.wrapper {
	width: 100%;
	overflow: hidden;
	position: relative;
}

.container {
	max-width: 1230px;
	margin: 0 auto;
	padding: 0 25px;
	position: relative;
}

.clearfix {
	&:after {
		clear: both;
		display: table;
	}
}

ul, ol {
	li {
		line-height: 0;
		position: relative;
	}
}

sup {
	font-size: 65%;
	margin: (-5 * 100vw / 1920) 0;
	display: inline-block;
	position: relative;
	top: -0.4em;
}

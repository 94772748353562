.table {
	@include media($w1024) {
		overflow-x: auto;
	}
	table {
		width: 100%;
		margin: 0;
		@include media($w1024) {
			width: 1000px;
		}
		@include media($w768) {
			width: 900px;
		}
		thead {
			tr {
				th {
					height: (50 * 100vw / 1920);
					font-size: (19 * 100vw / 1920);
					font-weight: 600;
					line-height: (30 * 100vw / 1920);
					text-align: left;
					vertical-align: top;
					padding: 0 (10 * 100vw / 1920);
					@include media($w1024) {
						height: 30px;
						font-size: 14px;
						line-height: 20px;
						padding: 0 5px;
		      }
					&:first-child {
						text-align: center;
						padding: 0 (40 * 100vw / 1920) 0 0;
						@include media($w1024) {
							padding: 0 20px 0 0;
			      }
					}
					span {
						cursor: pointer;
						padding: 0 (22 * 100vw / 1920) 0 0;
						display: inline-block;
						position: relative;
						@include media($w1024) {
							padding: 0 16px 0 0;
						}
						&:after {
							@include translateY;
							content: '';
							background-size: contain;
							background-repeat: no-repeat;
							background-position: center center;
							width: (18 * 100vw / 1920);
							height: (18 * 100vw / 1920);
							position: absolute;
							top: calc(50% - (1 * 100vw / 1920));
							right: 0;
							@include media($w1024) {
								width: 12px;
								height: 12px;
								top: calc(50% - 1px);
							}
						}
						&.no {
							&:after {
								background-image: url(../images/icon--sort--no.svg);
							}
						}
						&.asc {
							&:after {
								background-image: url(../images/icon--sort--asc.svg);
							}
						}
						&.dsc {
							&:after {
								background-image: url(../images/icon--sort--dsc.svg);
							}
						}
					}
				}
			}
		}
		tbody {
			tr {
				td {
					@include transition;
					border-bottom: (1 * 100vw / 1920) solid $green-1;
					height: (115 * 100vw / 1920);
					font-size: (19 * 100vw / 1920);
					font-weight: 400;
					line-height: (30 * 100vw / 1920);
					text-align: left;
					vertical-align: middle;
					padding: 0 (10 * 100vw / 1920);
					@include media($w1024) {
						height: 60px;
						font-size: 14px;
						line-height: 20px;
						padding: 0 5px;
		      }
					&:first-child {
						text-align: center;
						padding: 0 (40 * 100vw / 1920) 0 0;
						@include media($w1024) {
							padding: 0 20px 0 0;
			      }
					}
					sup {
						top: -0.5em;
					}
					span {
						&.status {
							font-weight: 600;
							color: $green-1;
							padding: 0 0 0 (20 * 100vw / 1920);
							display: inline-block;
							position: relative;
							@include media($w1024) {
								padding: 0 0 0 16px;
				      }
							&:before {
								@include translateY;
								content: '';
								border-radius: 50%;
								background-color: $green-1;
								width: (10 * 100vw / 1920);
								height: (10 * 100vw / 1920);
								position: absolute;
								top: calc(50% - (1 * 100vw / 1920));
								left: 0;
								@include media($w1024) {
									width: 8px;
									height: 8px;
					      }
							}
							&--reservation {
								color: $yellow;
								&:before {
									background-color: $yellow;
								}
							}
							&--sold {
								color: $red;
								&:before {
									background-color: $red;
								}
							}
						}
					}
					&.pdf {
						img {
							width: (26.5 * 100vw / 1920);
							display: inline-block;
							position: relative;
							top: (5 * 100vw / 1920);
							@include media($w1024) {
								width: 17px;
								top: 4px;
							}
						}
					}
				}
				&.reservation {
					td {
						border-bottom-color: $yellow;
					}
				}
				&.sold {
					td {
						border-bottom-color: $red;
					}
				}
				&:hover {
					td {
						background-color: $gray;
						.btn {
							background-color: $green-1;
							color: $white;
							&:hover {
								background-color: darken($green-1,5%);
							}
						}
					}
				}
			}
		}
	}
}

.page-title {
  margin: 0 0 (55 * 100vw / 1920);
  padding: 0 (120 * 100vw / 1920);
  @include media($w1024) {
    margin: 0 0 50px;
    padding: 0 30px;
  }
  @include media($w768) {
    text-align: center;
  }
  h1 {
    color: $green-3;
  }
  h5 {
    margin: (20 * 100vw / 1920) 0 0;
    @include media($w1024) {
      margin: 10px 0 0;
    }
    a {
      color: $green-1;
      margin: 0 0 0 (20 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 0 15px;
      }
      &:hover {
        color: $green-2;
      }
    }
  }
}

.page-template-flats {
  .page-title {
    @include media($w1024) {
      margin: 0 0 40px;
    }
  }
}

.page-template-gallery {
  .page-title {
    margin: 0 0 (65 * 100vw / 1920);
    @include media($w1024) {
      margin: 0 0 50px;
    }
  }
}

.page-template-contact {
  .page-title {
    @include media($w768) {
      margin: 0 0 45px;
    }
  }
}

.page-template-text {
  .page-title {
    @include media($w768) {
      text-align: left;
      margin: 0 0 40px;
    }
  }
}

.form-rules-wrapper {
  margin: (11 * 100vw / 1920) 0 (22 * 100vw / 1920);
  @include media($w1024) {
		margin: 8px 0 18px;
	}
  label {
    .form-rules {
      span {
        &.wpcf7-list-item {
          margin: 0;
          display: inline-block;
          input[type="checkbox"] {
            cursor: pointer;
            margin: 0 (10 * 100vw / 1920) 0 0;
            position: relative;
            top: (1 * 100vw / 1920);
            @include media($w1024) {
              margin: 0 6px 0 0;
              top: 2px;
          	}
          }
        }
      }
      .wpcf7-not-valid-tip {
        @include translateReset;
        width: (300 * 100vw / 1920);
        min-width: auto;
        text-align: left;
        padding: 0;
        position: absolute;
        top: calc(100% + (10 * 100vw / 1920));
        left: 0;
        @include media($w1024) {
          width: 300px;
          top: calc(100% + 10px);
        }
      }
    }
    span {
      font-size: (15 * 100vw / 1920);
      line-height: (26 * 100vw / 1920);
      color: rgba($black,0.5);
      cursor: pointer;
      @include media($w1024) {
        font-size: 12px;
        line-height: 16px;
      }
      a {
        text-decoration: underline;
        color: rgba($black,0.5);
        &:hover {
          color: $green-1;
        }
      }
    }
  }
}

.form-submit {
  justify-content: flex-end;
  display: flex;
  position: relative;
  .ajax-loader {
    @include translateY;
    position: absolute;
    left: 0;
  }
}

.wpcf7-not-valid-tip {
  @include translateY;
  width: 100%;
  font-size: (11 * 100vw / 1920) !important;
  line-height: (15 * 100vw / 1920) !important;
  text-align: right;
  color: $red !important;
  pointer-events: none;
  padding: 0 (30 * 100vw / 1920) 0 0;
  position: absolute;
  top: calc(50% - (1 * 100vw / 1920));
  right: 0;
  @include media($w1024) {
    font-size: 10px !important;
    line-height: 14px !important;
    padding: 0 15px 0 0;
    top: calc(50% + 1px);
  }
}

.wpcf7-response-output {
  font-size: (15 * 100vw / 1920) !important;
  line-height: (26 * 100vw / 1920) !important;
  margin: (32 * 100vw / 1920) 0 0 !important;
  padding: (10 * 100vw / 1920) (20 * 100vw / 1920) !important;
  @include media($w1024) {
    font-size: 13px !important;
    line-height: 17px !important;
    margin: 20px 0 0 !important;
    padding: 10px 14px !important;
  }
}

.page-location {
  .location {
    &__copy {
      flex: 0 0 (660 * 100vw / 1920);
      padding: 0 0 0 (120 * 100vw / 1920);
      @include media($w1024) {
        flex: 0 0 40%;
        padding: 0 0 0 30px;
      }
      @include media($w768) {
        flex: 0 0 100%;
        text-align: center;
        padding: 0 30px;
      }
    }
    &__list {
      margin: 0 0 (60 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 35px;
      }
    }
    &__list-item {
      align-items: center;
      display: flex;
      @include media($w768) {
        flex-wrap: wrap;
        align-items: flex-start;
      }
      & + .location {
        &__list-item {
          margin: (30 * 100vw / 1920) 0 0;
          @include media($w1024) {
            margin: 15px 0 0;
          }
        }
      }
      img {
        width: (50 * 100vw / 1920);
        @include media($w1024) {
          width: 40px;
        }
        @include media($w768) {
          width: 50px;
          margin: 0 auto 15px;
        }
        @include media($w560) {
          width: 40px;
          margin: 0 auto 10px;
        }
      }
      p {
        font-weight: 600;
        line-height: (26 * 100vw / 1920);
        padding: 0 (20 * 100vw / 1920);
        @include media($w1024) {
          line-height: 22px;
          padding: 0 20px 0 15px;
        }
        @include media($w768) {
          flex: 0 0 100%;
          padding: 0;
        }
        br {
          @include media($w768) {
            display: none;
          }
        }
      }
    }
  }
  .page-title {
    padding: 0;
    @include media($w1024) {
      margin: 0 0 25px;
    }
    @include media($w768) {
      margin: 0 0 40px;
    }
  }
}

.page-flats {
  .flats {
    &__filtration {
      margin: 0 0 (70 * 100vw / 1920);
      padding: 0 (120 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 55px;
        padding: 0 30px;
      }
      form {
        align-items: flex-end;
        display: flex;
        @include media($w768) {
          flex-wrap: wrap;
        }
      }
      h6 {
        font-weight: 500;
        margin: 0 0 (10 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 10px;
        }
      }
    }
    &__build {
      flex: 0 0 (180 * 100vw / 1920);
      @include media($w1024) {
        flex: 0 0 120px;
        margin: 0 -10px 0 0;
      }
      @include media($w768) {
        margin: 0;
      }
      h6 {
        margin: 0 0 (6 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 6px;
        }
      }
    }
    &__floor,
    &__rooms,
    &__surface {
      flex: 0 0 (290 * 100vw / 1920);
      margin: 0 0 0 (80 * 100vw / 1920);
      @include media($w1024) {
        flex: 0 0 150px;
        margin: 0 0 0 40px;
      }
    }
    &__floor {
      @include media($w768) {
        flex: 0 0 calc(100% - 175px);
        margin: 0 0 0 30px;
      }
    }
    &__rooms,
    &__surface {
      @include media($w768) {
        flex: 0 0 calc(50% - 57px);
        margin: 20px 0 0 40px;
      }
    }
    &__rooms {
      @include media($w768) {
        margin: 20px 0 0;
      }
    }
    &__reset {
      border: (1 * 100vw / 1920) solid rgba($black,0.2);
      width: (62 * 100vw / 1920);
      height: (62 * 100vw / 1920);
      margin: 0 0 0 (65 * 100vw / 1920);
      padding: 0 0 (2 * 100vw / 1920);
      justify-content: center;
      align-items: center;
      display: flex;
      @include media($w1024) {
        border-width: 1px;
        width: 38px;
        height: 38px;
        margin: 0 0 0 40px;
        padding: 0 0 1px;
      }
      @include media($w768) {
        margin: 0 0 0 35px;
      }
      img {
        width: (22 * 100vw / 1920);
        @include media($w1024) {
          width: 14px;
          opacity: 0.5;
        }
      }
      &:hover {
        border-color: $gray;
        background-color: $gray;
      }
    }
  }
}

.page-flat {
  .flat {
    &__body {
      padding: 0 (120 * 100vw / 1920);
      display: flex;
      @include media($w1024) {
        padding: 0 30px;
      }
      @include media($w768) {
        flex-wrap: wrap;
      }
      .page-title {
        padding: 0;
      }
    }
  }
}

svg {
	max-width: 100%;
	position: relative;
	z-index: 5;
	&:not(:root) {
		overflow: hidden;
	}
}

.search-3d {
	&__buildig-selector {
		padding: 0 15px 15px;
		&:after {
			content: '';
			display: block;
			clear: both;
		}
		li {
			float: left;
			width: 25%;
			@include media($w560) {
				width: 50%;
			}
			display: block;
			position: relative;
			line-height: 1;
			a {
				display: block;
				padding: 10px 15px 10px 25px;
			}
			&:before {
				content: '';
				background-image: url(../images/icon--list.svg);
				background-size: cover;
				width: 13px;
				height: 15px;
				top: 9px;
				position: absolute;
				left: 0;
			}
		}
		&__separator {
			display: block;
			width: 100% !important;
			padding: 35px 15px 15px 0;
			clear: both;
			font-weight: 500;
			&:before {
				display: none;
			}
		}
	}
	&--step-1 {
		padding: (10 * 100vw / 1920) 0 0;
		@include media($w1024) {
			padding: 0;
		}
	}
	&--step-2 {
		position: relative;
		.search-3d {
			&__view {
				text-align: center;
				@include media($w768) {
					margin: 0 0 50px;
				}
				svg {
					height: auto;
					width: (962 * 100vw / 1920);
					@include media($w1024) {
						width: 600px;
					}
					
					@include media($w768) {
						width: 100vw;
					}
				}
			}
			&__floor-selector {
				border: (1 * 100vw / 1920) solid $green-1;
				width: (152 * 100vw / 1920);
				text-align: center;
				padding: (30 * 100vw / 1920) 0 (20 * 100vw / 1920);
				position: absolute;
				top: (5 * 100vw / 1920);
				right: (55 * 100vw / 1920);
				@include media($w1024) {
					border-width: 1px;
					border-right-width: 0;
					width: 90px;
					padding: 20px 0 10px;
					top: 5px;
					right: 0;
				}
				@include media($w768) {
					border-right-width: 1px;
					width: calc(100% - 60px);
					margin: 0 30px;
					padding: 10px 0;
					justify-content: center;
					align-items: center;
					display: flex;
					position: relative;
					top: auto;
					right: auto;
				}
				@include media($w560) {
					border-left-width: 0;
					border-right-width: 0;
					width: 100%;
					margin: 0;
					padding: 10px 30px;
				}
				&__header {
					font-size: (24 * 100vw / 1920);
					font-weight: 600;
					line-height: (30 * 100vw / 1920);
					margin: 0 0 (10 * 100vw / 1920);
					@include media($w1024) {
						font-size: 16px;
						line-height: 22px;
						margin: 0 0 5px;
					}
					@include media($w768) {
						line-height: 20px;
						margin: 0 10px 0 0;
					}
					@include media($w560) {
						font-size: 14px;
						line-height: 18px;
					}
				}
				&__floors {
					@include media($w768) {
						display: flex;
					}
					a {
						width: (97 * 100vw / 1920);
						height: (110 * 100vw / 1920);
						font-size: (36 * 100vw / 1920);
						font-weight: 700;
						line-height: (42 * 100vw / 1920);
						color: $black;
						margin: 0 auto;
						justify-content: center;
						align-items: center;
						display: flex;
						position: relative;
						@include media($w1024) {
							width: 60px;
							height: 68px;
							font-size: 20px;
							line-height: 26px;
						}
						@include media($w768) {
							width: 50px;
							height: 57px;
							font-size: 18px;
							line-height: 24px;
						}
						@include media($w560) {
							width: 40px;
							height: 46px;
							font-size: 16px;
							line-height: 22px;
						}
						&:after {
							@include translate;
							content: '';
							background: url('../images/3d-search/background.svg') no-repeat 50% 50%;
							background-size: contain;
							width: 100%;
							height: 100%;
							display: block;
							position: absolute;
							z-index: -25;
							opacity: 0;
							transition: 0.5s all;
						}
						&:hover {
							&:after {
								opacity: 0.3;
							}
						}
						&.active {
							color: $white;
							&:after {
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
	.search-3d {
		&__view {
			&__floor-link,
			&__apartment-link {
				@include transition;
				opacity: 0;
				cursor: pointer !important;
				&:hover {
					opacity: 0.55;
				}
			}
			&__floor-link {
			}
			&__apartment-link {
				opacity: 0.2;
				&.sold {
					fill: $red;
					opacity: 0.85;
				}
				&.reserved {
					fill: $yellow;
					opacity: 0.85;
					&:hover {
						opacity: 0.5;
					}
				}
			}
		}
	}
}

.api {
	&__hidden {
		display: none;
		&--show-768 {
			@include media($w768) {
				padding: 20px 0;
				display: block;
			}
		}
	}
	&__cloud {
		border-radius: (25 * 100vw / 1920) (25 * 100vw / 1920) (25 * 100vw / 1920) 0;
		background: $white;
		width: (300 * 100vw / 1920);
		color: $brown-dark;
		opacity: 0;
		pointer-events: none;
		padding: (20 * 100vw / 1920) 0 0;
		box-shadow: 0 (3 * 100vw / 1920) (10 * 100vw / 1920) rgba($black, 0.15);
		position: fixed;
		z-index: 880;
		@include media($w1024) {
			display: none !important;
		}
		&.show {
			opacity: 1;
		}
		&__header {
			@include translateX;
			background: url('../images/3d-search/background.svg') no-repeat 50% 50%;
			background-size: contain;
			width: (70 * 100vw / 1920);
			height: (100 * 100vw / 1920);
			font-size: (26 * 100vw / 1920);
			font-weight: 600;
			line-height: (32 * 100vw / 1920);
			text-align: center;
			text-transform: uppercase;
			color: $white;
			justify-content: center;
			align-items: center;
			display: flex;
			position: absolute;
			top: (-60 * 100vw / 1920);
		}
		&__contents {
			font-weight: 300;
			padding: (20 * 100vw / 1920) (25 * 100vw / 1920) 0;
		}
		&__info {
			&--area {
				font-size: (36 * 100vw / 1920);
				font-weight: bold;
				display: block;
			}
			&--price {
				font-size: (16 * 100vw / 1920);
				line-height: (22 * 100vw / 1920);
				&:before {
					content: '|';
					opacity: 0.6;
					margin: 0 (9 * 100vw / 1920) 0 (7 * 100vw / 1920);
					display: inline-block;
				}
			}
			&--status {
				border-radius: 0 0 (25 * 100vw / 1920) 0;
				font-size: (16 * 100vw / 1920);
				line-height: (40 * 100vw / 1920);
				color: $white;
				&.status-avalible {
					background: $green-1;
				}
				&.status-reservation {
					background: $yellow;
				}
				&.status-sold {
					background: $red;
				}
			}
		}
		&__footer {
			text-align: center;
			margin: (20 * 100vw / 1920) 0 0;
		}
	}
	&__b-cloud {
		border-radius: (25 * 100vw / 1920) (25 * 100vw / 1920) (25 * 100vw / 1920) 0;
		background: $white;
		width: (240 * 100vw / 1920);
		color: $brown-dark;
		opacity: 0;
		pointer-events: none;
		box-shadow: 0 (3 * 100vw / 1920) (10 * 100vw / 1920) rgba($black, 0.15);
		position: fixed;
		z-index: 880;
		@include media($w1024) {
			display: none !important;
		}
		&.show {
			opacity: 1;
		}
		&__header {
			@include translateX;
			background: url('../images/3d-search/background.svg') no-repeat 50% 50%;
			background-size: contain;
			width: (80 * 100vw / 1920);
			height: (100 * 100vw / 1920);
			font-size: (36 * 100vw / 1920);
			line-height: (100 * 100vw / 1920);
			text-align: center;
			color: $white;
			position: absolute;
			top: (-50 * 100vw / 1920);
		}
		&__contents {
			line-height: 1.3;
			text-align: center;
			padding: (55 * 100vw / 1920) (20 * 100vw / 1920) (20 * 100vw / 1920);
		}
		&__info {
			font-size: (36 * 100vw / 1920);
			&--floor {
				font-weight: 500;
				text-transform: lowercase;
			}
			&--building {
			}
		}
	}
}

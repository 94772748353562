.page-home {
  .flats-size {
    margin: 0 0 (170 * 100vw / 1920);
    padding: 0 (120 * 100vw / 1920);
    @include media($w1024) {
      margin: 0 0 60px;
      padding: 0;
    }
    &__outer {
      background-size: cover;
      background-position: center center;
      justify-content: flex-end;
      display: flex;
      position: relative;
    }
    &__inner-bg {
      width: (860 * 100vw / 1920);
      height: 100%;
      overflow: hidden;
      position: absolute;
      z-index: 25;
      top: 0;
      left: calc(100% - (520 * 100vw / 1920));
      @include media($w1024) {
        width: 550px;
        left: calc(100% - 300px);
      }
      @include media($w560) {
        width: 150%;
        left: -25%;
      }
      img {
        @include translateY;
        width: 100%;
        top: calc(50% + (10 * 100vw / 1920));
        @include media($w1024) {
          top: calc(50% + 10px);
        }
      }
    }
    &__inner {
      flex: 0 0 (520 * 100vw / 1920);
      padding: (100 * 100vw / 1920) (95 * 100vw / 1920) (115 * 100vw / 1920) (95 * 100vw / 1920);
      position: relative;
      z-index: 50;
      @include media($w1024) {
        flex: 0 0 300px;
        padding: 55px 30px 60px;
      }
      @include media($w560) {
        flex: 0 0 100%;
        text-align: center;
        padding: 0 30px;
      }
      h2 {
        margin: 0 0 (40 * 100vw / 1920);
        @include media($w1024) {
          margin: 0 0 15px;
        }
        @include media($w560) {
          max-width: 80%;
          margin: 0 auto 15px;
        }
      }
      p {
        width: calc(100% + (50 * 100vw / 1920));
        margin: 0 (-50 * 100vw / 1920) (50 * 100vw / 1920) 0;
        @include media($w1024) {
          width: 100%;
          margin: 0 0 20px;
        }
      }
    }
    &__item {
      & + .flats-size {
        &__item {
          margin: (30 * 100vw / 1920) 0 0;
          @include media($w1024) {
            margin: 10px 0 0;
          }
        }
      }
    }
  }
}

.bottom-panel {
  margin: 0 0 (110 * 100vw / 1920);
  padding: 0 (120 * 100vw / 1920);
  justify-content: space-between;
  display: flex;
  position: relative;
  z-index: 25;
  @include media($w1024) {
    margin: 0 0 35px;
    padding: 0 30px;
  }
  @include media($w768) {
    flex-wrap: wrap;
  }
  @include media($w560) {
    text-align: center;
    margin: 0 0 30px;
  }
  &__office {
    flex: 0 0 (515 * 100vw / 1920);
    @include media($w1024) {
      flex: 0 0 34%;
    }
    @include media($w768) {
      flex: 0 0 100%;
      margin: 0 0 30px;
    }
    h2,
    p {
      margin: 0 0 (25 * 100vw / 1920);
      @include media($w1024) {
        margin: 0 0 16px;
      }
    }
    p {
      @include media($w1024) {
        margin: 0 0 20px;
      }
    }
    .socials {
      @include media($w560) {
        justify-content: center;
      }
    }
  }
  &__contact {
    margin: 0 auto 0 0;
    @include media($w1024) {
      flex: 0 0 39%;
    }
    @include media($w768) {
      flex: 0 0 60%;
    }
    @include media($w560) {
      flex: 0 0 100%;
      margin: 0 0 38px;
    }
  }
  &__contact-box {
    font-size: (24 * 100vw / 1920);
    font-weight: 600;
    line-height: (30 * 100vw / 1920);
    margin: 0 0 (40 * 100vw / 1920);
    padding: 0 0 0 (22 * 100vw / 1920);
    position: relative;
    @include media($w1024) {
      font-size: 16px;
      line-height: 22px;
      margin: 0 0 22px;
      padding: 0 0 0 15px;
    }
    @include media($w560) {
      margin: 0 0 28px;
      padding: 0;
    }
    &:before {
      content: '';
      background-color: $green-1;
      width: (2 * 100vw / 1920);
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      @include media($w1024) {
        width: 2px;
      }
      @include media($w560) {
        display: none;
      }
    }
  }
  &__menu {
    @include media($w1024) {
      flex: 0 0 27%;
    }
    @include media($w768) {
      flex: 0 0 40%;
    }
    @include media($w560) {
      flex: 0 0 100%;
    }
    nav {
      ul {
        li {
          & + li {
            margin: (5 * 100vw / 1920) 0 0;
            @include media($w1024) {
              margin: 4px 0 0;
            }
          }
          a {
            font-size: (19 * 100vw / 1920);
            font-weight: 500;
            line-height: (30 * 100vw / 1920);
            @include media($w1024) {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }
}

.page-flat {
  .flat {
    &__right {
      flex: 1 1 auto;
      flex-wrap: wrap;
      justify-content: flex-end;
      display: flex;
      @include media($w768) {
        justify-content: center;
      }
    }
    &__view {
      flex: 0 0 100%;
      overflow: hidden;
      justify-content: center;
      display: flex;
      position: relative;
      z-index: 25;
      img {
        height: (720 * 100vw / 1920);
        margin: 0 auto;
        @include media($w1024) {
          height: 380px;
        }
        @include media($w768) {
          width: 100%;
          height: auto;
        }
      }
    }
    &__position {
      flex: 0 0 (150 * 100vw / 1920);
      margin: (-90 * 100vw / 1920) 0 0;
      position: relative;
      z-index: 50;
      @include media($w1024) {
        flex: 0 0 100px;
        margin: -50px 0 0;
      }
      @include media($w768) {
        margin: 20px 0 0;
      }
      img {
        width: 100%;
      }
    }
  }
}

.features {
  padding: 0 (120 * 100vw / 1920);
  position: relative;
  @include media($w1024) {
    padding: 0 30px;
  }
  &__item {
    align-items: center;
    display: flex;
    @include media($w768) {
      text-align: center;
      flex-wrap: wrap;
      justify-content: center;
    }
    & + .features {
      &__item {
        margin: (145 * 100vw / 1920) 0 0;
        @include media($w1024) {
          margin: 60px 0 0;
        }
      }
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      .features {
        &__copy {
          padding: 0 (130 * 100vw / 1920) 0 0;
          @include media($w1024) {
            padding: 0 30px 0 0;
          }
        }
      }
    }
  }
  &__image {
    flex: 0 0 (685 * 100vw / 1920);
    padding: 0 0 0 (75 * 100vw / 1920);
    position: relative;
    @include media($w1024) {
      flex: 0 0 42.5%;
      padding: 0;
    }
    @include media($w768) {
      flex: 0 0 360px;
      margin: 0 0 30px;
      padding: 0;
    }
    @include media($w560) {
      flex: 0 0 260px;
    }
    & > img {
      width: 100%;
      position: relative;
      z-index: 25;
    }
  }
  &__addon-01,
  &__addon-02 {
    position: absolute;
    img {
      width: (143 * 100vw / 1920);
      @include media($w1024) {
        width: 90px;
      }
      @include media($w768) {
        width: 80px;
      }
      @include media($w560) {
        width: 70px;
      }
    }
  }
  &__addon-01 {
    z-index: 50;
    top: (65 * 100vw / 1920);
    left: 0;
    @include media($w1024) {
      top: 10%;
      left: -15px;
    }
    @include media($w768) {
      top: 45px;
      left: -20px;
    }
    @include media($w560) {
      top: 30px;
    }
  }
  &__addon-02 {
    z-index: 0;
    bottom: (-25 * 100vw / 1920);
    right: 0;
    @include media($w1024) {
      bottom: 0;
      right: 0;
    }
    @include media($w768) {
      bottom: 45px;
      right: -20px;
    }
    @include media($w560) {
      bottom: 30px;
    }
  }
  &__copy {
    flex: 1 1 auto;
    padding: 0 0 0 (130 * 100vw / 1920);
    @include media($w1024) {
      padding: 0 0 0 30px;
    }
    @include media($w768) {
      flex: 0 0 100%;
      padding: 0 !important;
    }
    .btn {
      margin: (60 * 100vw / 1920) 0 0;
      @include media($w1024) {
  	    margin: 25px 0 0;
  	  }
    }
  }
  &--revers {
    .features {
      &__item {
        &:nth-child(odd) {
          flex-direction: row-reverse;
          .features {
            &__copy {
              padding: 0 (130 * 100vw / 1920) 0 0;
              @include media($w1024) {
                padding: 0 30px 0 0;
              }
            }
          }
        }
        &:nth-child(even) {
          flex-direction: row;
          .features {
            &__copy {
              padding: 0 0 0 (130 * 100vw / 1920);
              @include media($w1024) {
                padding: 0 0 0 30px;
              }
            }
          }
        }
      }
    }
  }
}

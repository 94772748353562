.page-gallery {
  .gallery {
    &__list {
      flex-wrap: wrap;
      display: flex;
    }
    &__item {
      background-color: $green-3;
      flex: 0 0 calc(50% - (0.5 * 100vw / 1920));
      height: (610 * 100vw / 1920);
      margin: 0 0 (1 * 100vw / 1920);
      overflow: hidden;
      position: relative;
      @include media($w1024) {
        flex: 0 0 50%;
        margin: 0;
      }
      @include media($w768) {
        height: (660 * 100vw / 1920);
      }
      @include media($w560) {
        height: (710 * 100vw / 1920);
      }
      &:nth-child(odd) {
        margin: 0 (1 * 100vw / 1920) (1 * 100vw / 1920) 0;
        @include media($w1024) {
          margin: 0;
        }
      }
      &:before {
        @include transition;
        @include translate;
        content: '';
        background-image: url(../images/icon--zoom.svg);
        background-size: cover;
        width: (105.5 * 100vw / 1920);
        height: (105.5 * 100vw / 1920);
        opacity: 0;
        pointer-events: none;
        position: absolute;
        z-index: 25;
      }
      a {
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        position: absolute;
        top: 0;
        left: 0;
        img {
          @include transition;
          min-width: 100%;
          min-height: 100%;
        }
      }
      &:hover {
        &:before {
          opacity: 1;
        }
        a {
          img {
            opacity: 0.2;
          }
        }
      }
    }
  }
}
